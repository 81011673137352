const navigation = [
  {
    id: "infosDesWebsites",
    nameFr: "Infos des websites",
    href: "/infos-des-websites",
    subNavigation: [
      {
        id: "infosDesWebsites",
        nameFr: "Websites Premium",
        description: "Liste des clients Premium et des produits souscrits",
        href: "/infos-des-websites-premium",
      },
      {
        id: "infosDesWebsites",
        nameFr: "Websites Partageables",
        description:
          "Liste des clients sur les plateformes partageables et des produits souscrits",
        href: "/infos-des-websites-partageables",
      },
      {
        id: "infosDesWebsites",
        nameFr: "Websites Mobile",
        description: "Sites mobile actifs",
        href: "/infos-des-websites-mobile",
      },
      {
        id: "coggle",
        nameFr: "Coggle",
        description: "Généalogie des thèmes",
        href: "/infos-des-websites/coggle",
      },
    ],
  },
  {
    id: "genesis",
    nameFr: "Genesis",
    href: "/genesis",
    subNavigation: [
      {
        id: "releaseNote",
        nameFr: "Release Note",
        nameEn: "Release Note",
        description: "",
        href: "/genesis",
      },
      {
        id: "about",
        nameFr: "Page à propos",
        nameEn: "About Page",
        description: "",
        href: "/genesis/about",
      },
      {
        id: "career",
        nameFr: "Page carrière",
        nameEn: "Career Page",
        description: "",
        href: "/genesis/career",
      },
      {
        id: "catalog",
        nameFr: "Page catalogue",
        nameEn: "Catalog Page",
        description: "",
        href: "/genesis/catalog",
      },
      {
        id: "contact",
        nameFr: "Page contact",
        nameEn: "Contact Page",
        description: "",
        href: "/genesis/contact",
      },
      {
        id: "financing",
        nameFr: "Page financement",
        nameEn: "Financing Page",
        description: "",
        href: "/genesis/financing",
      },
      {
        id: "footer",
        nameFr: "Footer",
        nameEn: "Footer",
        description: "",
        href: "/genesis/footer",
      },
      {
        id: "header",
        nameFr: "Header",
        nameEn: "Header",
        description: "",
        href: "/genesis/header",
      },
      {
        id: "homepage",
        nameFr: "Page d'accueil",
        nameEn: "Homepage",
        description: "",
        href: "/genesis/homepage",
      },
      {
        id: "inventory",
        nameFr: "Page inventaire",
        nameEn: "Inventory Page",
        description: "",
        href: "/genesis/inventory",
      },
      {
        id: "news",
        nameFr: "Page nouvelles",
        nameEn: "News",
        description: "",
        href: "/genesis/news",
      },
      {
        id: "promotion",
        nameFr: "Page promotions",
        namnameEneFr: "Special Offers",
        description: "",
        href: "/genesis/promotions",
      },
      {
        id: "reviews",
        nameFr: "Page témoignage",
        nameEn: "Reviews Page",
        description: "",
        href: "/genesis/reviews",
      },
      {
        id: "services",
        nameFr: "Page services",
        nameEn: "Service Pages",
        description: "",
        href: "/genesis/services",
      },
      {
        id: "team",
        nameFr: "Page équipe",
        nameEn: "Team",
        description: "",
        href: "/genesis/team",
      },
    ],
  },
  {
    id: "products",
    nameFr: "Les produits 360",
    href: "/les-produits-360",
    subNavigation: [
      {
        id: "access-360",
        nameFr: "Access 360",
        description: "Outil d'accessibilité",
        href: "/les-produits-360/access-360",
      },
      {
        id: "cookie-banner",
        nameFr: "Cookie Banner",
        description: "",
        href: "/les-produits-360/cookie-banner",
      },
      {
        id: "chat-messenger",
        nameFr: "Chat Messenger",
        description: "L'outil de clavardage de facebook",
        href: "/les-produits-360/chat-messenger",
      },
      {
        id: "why-buy-page",
        nameFr: "Page Why Buy",
        description: "Insérer la page Why Buy sur un site web",
        href: "/les-produits-360/insertion-why-buy",
      },
    ],
  },
  {
    id: "docTechnique",
    nameFr: "Documentation technique",
    href: "/documentation-technique",
    subNavigation: [
      {
        id: "accessibilite",
        nameFr: "Accessibilité",
        description: "",
        href: "/documentation-technique/accessibilite",
      },
      {
        id: "bodystyles",
        nameFr: "Bodystyles",
        description: "",
        href: "/documentation-technique/bodystyles",
      },
      {
        id: "colanders",
        nameFr: "Colanders",
        description: "",
        href: "/documentation-technique/colanders",
      },
      {
        id: "config-keys",
        nameFr: "Clés de config",
        description: "",
        href: "/documentation-technique/cles-de-config",
      },
      {
        id: "datadog",
        nameFr: "Datadog",
        description: "",
        href: "/documentation-technique/datadog",
      },
      {
        id: "forms",
        nameFr: "Formulaires",
        description: "",
        href: "/documentation-technique/formulaires",
        secondNav: [
          {
            id: "general",
            nameFr: "General",
            description: "",
            href: "/documentation-technique/formulaires/general",
          },
          {
            id: "financing",
            nameFr: "Financing",
            description: "",
            href: "/documentation-technique/formulaires/financing",
          },
          {
            id: "sales-new",
            nameFr: "Sales New",
            description: "",
            href: "/documentation-technique/formulaires/sales-new",
          },
          {
            id: "sales-preowned",
            nameFr: "Sales Preowned",
            description: "",
            href: "/documentation-technique/formulaires/sales-preowned",
          },
          {
            id: "service",
            nameFr: "Service",
            description: "",
            href: "/documentation-technique/formulaires/service",
          },

          {
            id: "parts",
            nameFr: "Parts",
            description: "",
            href: "/documentation-technique/formulaires/parts",
          },

          {
            id: "powerhouse",
            nameFr: "Powerhouse",
            description: "",
            href: "/documentation-technique/formulaires/powerhouse",
          },
        ],
      },
      {
        id: "framestyles",
        nameFr: "Framestyles",
        description: "",
        href: "/documentation-technique/framestyles",
      },
      {
        id: "InventoryTechnicalDoc",
        nameFr: "Inventaire",
        description: "Toutes les spécificités relatives à l'inventaire",
        href: "/documentation-technique/inventaire",
      },
      {
        id: "google-map",
        nameFr: "Google Map",
        description: "",
        href: "/documentation-technique/google-map",
      },
      {
        id: "makes",
        nameFr: "Marques",
        description: "",
        href: "/documentation-technique/marques",
      },
      {
        id: "manufacturer-programs",
        nameFr: "Programmes manufacturiers",
        description: "",
        href: "/documentation-technique/programmes-manufacturiers",
      },
      {
        id: "slugs",
        nameFr: "Slugs des départements",
        description: "",
        href: "/documentation-technique/slugs-des-departements",
      },
      {
        id: "sprintReviews",
        nameFr: "Revue de sprints",
        description: "Revues de sprint de l'équipe innovation",
        href: "/documentation-technique/sprint-reviews",
      },
      {
        id: "usedframes",
        nameFr: "Usedframes",
        description: "",
        href: "/documentation-technique/usedframes",
      },
      {
        id: "videos",
        nameFr: "Videos",
        description: "",
        href: "/documentation-technique/videos",
      },
      {
        id: "workflow",
        nameFr: "Workflow de travail",
        description: "",
        href: "/documentation-technique/workflow",
      },
    ],
  },
  {
    id: "PAM",
    nameFr: "Post Assembly Monitoring",
    href: "/pam",
    subNavigation: [
      {
        id: "ticket-creation",
        nameFr: "Ticket Creation",
        description: "",
        href: "/pam/ticket-creation",
      },
      {
        id: "ticket-status",
        nameFr: "Ticket Status",
        description: "",
        href: "/pam/ticket-status",
      },
      {
        id: "issue-types",
        nameFr: "Issue Types",
        description: "",
        href: "/pam/issue-types",
      },
      {
        id: "teams",
        nameFr: "Teams",
        description: "",
        href: "/pam/teams",
      },
      {
        id: "resolutions",
        nameFr: "Resolutions",
        description: "",
        href: "/pam/resolutions",
      },
      {
        id: "bonnes-pratiques",
        nameFr: "Bonnes pratiques",
        description: "",
        href: "/pam/good-practices",
      },
      {
        id: "tooltips",
        nameFr: "Tooltips",
        description: "",
        href: "/pam/tooltips",
      },
      {
        id: "faq",
        nameFr: "FAQ",
        description: "",
        href: "/pam/faq",
      },
    ],
  },
];

export default navigation;
