import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="pam/ticket-creation.md"
      location={props.location.pathname}
      h1="Ticket Creation"
      arrayStart="4"
      arrayEnd="5"
    />
  );
}
