import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="produits-360/chat-messenger.md"
      location={props.location.pathname}
      h1="Chat Messenger"
      arrayStart="2"
      arrayEnd="3"
    />
  );
}
