import { Fragment } from "react";
import { connect } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SubNavigationItem from "../molecule/SubNavigationItem";

const NavigationItem = (props) => {
  const { nav } = props;
  const { nameFr, subNavigation } = nav;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="group rounded-md inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            <span className="hover:text-grayLight">{nameFr}</span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 group-hover:text-primary"
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
              <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                <div className="bg-white">
                  {subNavigation.map((item) => (
                    <div key={item.href}>
                      <SubNavigationItem item={item} />
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

function mapStateToProps({ navigation }, { id }) {
  const nav = navigation[id];
  return {
    nav,
    navigation,
  };
}
export default connect(mapStateToProps)(NavigationItem);
