import Template from "../Template";
import data from "../../DATA/content/genesis/about.json";
export default function App(props) {
  return (
    <Template
      filepath="genesis/about.json"
      location={props.location.pathname}
      h1="Page à propos avec et sans bannière showroom"
      arrayStart="1"
      arrayEnd="2"
      data={data}
      layout="genesis"
    />
  );
}
