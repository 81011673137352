import Template from "../Template";
import data from "../../DATA/content/genesis/contact.json";
export default function App(props) {
  return (
    <Template
      filepath="genesis/contact.json"
      location={props.location.pathname}
      h1="Page contact Alpha et Bravo"
      arrayStart="1"
      arrayEnd="2"
      data={data}
      layout="genesis"
    />
  );
}
