import Template from "../Template";
import data from "../../DATA/content/documentation-technique/usedframes.json";
export default function App(props) {
  return (
    <Template
      filepath="documentation-technique/usedframes.json"
      location={props.location.pathname}
      h1="Used Frames"
      arrayStart="3"
      arrayEnd="4"
      data={data}
      layout="table"
    />
  );
}
