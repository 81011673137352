import Template from "../Template";
import data from "../../DATA/content/genesis/team.json";
export default function App(props) {
  return (
    <Template
      filepath="genesis/team.json"
      location={props.location.pathname}
      h1="Page équipe"
      arrayStart="1"
      arrayEnd="2"
      data={data}
      layout="genesis"
    />
  );
}
