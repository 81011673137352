import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="genesis/genesis-release-note.md"
      location={props.location.pathname}
      h1="Release Note"
      arrayStart="1"
      arrayEnd="2"
    />
  );
}
