import React, { Component } from "react";
import { GoogleLogin } from "react-google-login";
import { Logo } from "../_library/molecule";

class Login extends Component {
  // Error Handler
  responseGoogleError = (response) => {
    console.log(response);
  };

  render() {
    const { clienId, authedUser, connexionFailed } = this.props;

    return (
      <div className="w-screen h-screen flex justify-center items-center bg-secondary">
        <div className="p-10 rounded flex items-center flex-col shadow-md bg-gradient-to-r from-primary to-primaryDarken m-4">
          <div className="flex justify-center p-5">
            <Logo />
          </div>
          <p className="py-5 text-secondary ">
            Connectez-vous avec votre compte @solutionsmedias360.com,
            @360.agency ou @consultantauto.com
          </p>
          <GoogleLogin
            clientId={clienId}
            buttonText="Connexion"
            onSuccess={authedUser}
            onFailure={this.responseGoogleError}
            isSignedIn={true}
            cookiePolicy={"single_host_origin"}
          />

          {connexionFailed && (
            <div className="pt-5 text-red-700">
              Échec de la connexion: L'utilisateur ne fait pas partie du domaine
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Login;
