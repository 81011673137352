import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="infos-des-websites/coggle.md"
      location={props.location.pathname}
      h1="Généalogie des thèmes"
      arrayStart="0"
      arrayEnd="1"
    />
  );
}
