import logo from "../../_assets/img/logo-toolbox.svg";

const Logo = (props) => {
  return (
    <a href="/">
      <span className="sr-only">Workflow</span>
      <img src={logo} alt="Logo Toolbox" width="200px" height="300px" />
    </a>
  );
};

export default Logo;
