import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="produits-360/why-buy-page.md"
      location={props.location.pathname}
      h1="Why Buy Page"
      arrayStart="2"
      arrayEnd="3"
    />
  );
}
