import { connect } from "react-redux";
import Logout from "../molecule/Logout";

const AuthedUser = (props) => {
  const { clienId, onLogoutSuccess, userInfo } = props;

  return (
    <div className="inline-block rounded-full pr-5 h-8 hidden md:block">
      <img
        alt={userInfo.name}
        className="rounded-full float-left h-full mr-3"
        src={userInfo.imageUrl}
      />
      <Logout
        clienId={clienId}
        onLogoutSuccess={onLogoutSuccess}
        userInfo={userInfo}
      />
    </div>
  );
};

function mapStateToProps() {
  return {};
}
export default connect(mapStateToProps)(AuthedUser);
