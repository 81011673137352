import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { handleInitialData } from "./store/actions/shared";

import Nav from "./_library/organisms/Header";

import InfosDesWebsitesPremium from "./pages/infos-des-websites/InfosDesWebsitesPremium";
import InfosDesWebsitesPartageables from "./pages/infos-des-websites/InfosDesWebsitesPartageables";
import InfosDesWebsitesMobile from "./pages/infos-des-websites/InfosDesWebsitesMobile";
import Coggle from "./pages/infos-des-websites/Coggle";

import Landing from "./pages/Homepage";
import Login from "./pages/Login";

import NoResult from "./pages/404";

import GenesisReleaseNote from "./pages/genesis/GenesisReleaseNote";
import Footer from "./pages/genesis/Footer";
import Header from "./pages/genesis/Header";
import Homepage from "./pages/genesis/Homepage";
import Catalog from "./pages/genesis/Catalog";
import Inventory from "./pages/genesis/Inventory";
import Promotions from "./pages/genesis/Promotions";
import Financing from "./pages/genesis/Financing";
import About from "./pages/genesis/About";
import Contact from "./pages/genesis/Contact";
import News from "./pages/genesis/News";
import Reviews from "./pages/genesis/Reviews";
import Career from "./pages/genesis/Career";
import Services from "./pages/genesis/Services";
import Team from "./pages/genesis/Team";

import Access360 from "./pages/products/Access360";
import CookieBanner from "./pages/products/CookieBanner";
import ChatMessenger from "./pages/products/ChatMessenger";
import WhyBuyPage from "./pages/products/WhyBuyPage";

import Bodystyles from "./pages/doc-technique/Bodystyles";
import GoogleMap from "./pages/doc-technique/GoogleMap";
import DepartmentSlugs from "./pages/doc-technique/DepartmentSlugs";

import Forms from "./pages/doc-technique/formulaires/Forms";
import FormsSalesNew from "./pages/doc-technique/formulaires/FormsSalesNew";
import FormsSalesPreowned from "./pages/doc-technique/formulaires/FormsSalesPreowned";
import FormsService from "./pages/doc-technique/formulaires/FormsService";
import FormsGeneral from "./pages/doc-technique/formulaires/FormsGeneral";
import FormsParts from "./pages/doc-technique/formulaires/FormsParts";
import FormsFinancing from "./pages/doc-technique/formulaires/FormsFinancing";
import FormsPowerhouse from "./pages/doc-technique/formulaires/FormsPowerhouse";

import Colanders from "./pages/doc-technique/Colanders";
import Framestyles from "./pages/doc-technique/Framestyles";
import Usedframes from "./pages/doc-technique/Usedframes";
import Makes from "./pages/doc-technique/Makes";
import Accessibilite from "./pages/doc-technique/Accessibilite";
import Datadog from "./pages/doc-technique/Datadog";

import InventoryTechnicalDoc from "./pages/doc-technique/inventaire/InventoryTechnicalDoc";

import Videos from "./pages/doc-technique/Videos";
import SprintReviews from "./pages/doc-technique/SprintReviews";
import Workflow from "./pages/doc-technique/Workflow";
import ConfigKeys from "./pages/doc-technique/ConfigKeys";
import ManufacturerPrograms from "./pages/doc-technique/ManufacturerPrograms";

import TicketCreation from "./pages/pam/TicketCreation";
import TicketStatus from "./pages/pam/TicketStatus";
import IssueTypes from "./pages/pam/IssueTypes";
import Teams from "./pages/pam/Teams";
import Resolutions from "./pages/pam/Resolutions";
import GoodPractices from "./pages/pam/GoodPractices";
import Tooltips from "./pages/pam/Tooltips";
import Faq from "./pages/pam/Faq";

const CLIENT_ID =
  "5218555837-v4umbvqvf0svn55ktfdi0n1sk75f0pie.apps.googleusercontent.com";

class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      connexionFailed: false,
      userInfo: {
        name: "",
        emailId: "",
      },
    };
  }

  // Success Handler
  responseGoogleSuccess = (response) => {
    console.log("responseGoogleSuccess: ", response);
    let userInfo = {
      name: response.profileObj.name,
      emailId: response.profileObj.email,
      imageUrl: response.profileObj.imageUrl,
    };
    if (
      response.profileObj.email.includes("@solutionsmedias360.com") ||
      response.profileObj.email.includes("@360.agency") ||
      response.profileObj.email.includes("@consultantauto.com")
    ) {
      this.setState({ userInfo, isLoggedIn: true });
    } else {
      this.setState({ connexionFailed: true });
    }
  };

  // Error Handler
  responseGoogleError = (response) => {
    console.log(response);
  };

  // Logout Session and Update State
  logout = (response) => {
    console.log(response);
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false, connexionFailed: false });
  };

  componentDidMount() {
    this.props.handleInitialData();
  }

  render() {
    return (
      <Router>
        {this.state.isLoggedIn ? (
          <Fragment>
            <Nav
              clienId={CLIENT_ID}
              onLogoutSuccess={this.logout}
              userInfo={this.state.userInfo}
              connexionFailed={this.state.connexionFailed}
            />
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route
                exact
                path="/infos-des-websites-premium"
                component={InfosDesWebsitesPremium}
              />
              <Route
                exact
                path="/infos-des-websites-partageables"
                component={InfosDesWebsitesPartageables}
              />
              <Route
                exact
                path="/infos-des-websites-mobile"
                component={InfosDesWebsitesMobile}
              />
              <Route
                path="/infos-des-websites/coggle"
                exact
                component={Coggle}
              />
           
              <Route path="/genesis" exact component={GenesisReleaseNote} />
              <Route path="/genesis/homepage" exact component={Homepage} />
              <Route path="/genesis/footer" exact component={Footer} />
              <Route path="/genesis/header" exact component={Header} />
              <Route path="/genesis/catalog" exact component={Catalog} />
              <Route path="/genesis/inventory" exact component={Inventory} />
              <Route path="/genesis/promotions" exact component={Promotions} />
              <Route path="/genesis/financing" exact component={Financing} />
              <Route path="/genesis/about" exact component={About} />
              <Route path="/genesis/contact" exact component={Contact} />
              <Route path="/genesis/news" exact component={News} />
              <Route path="/genesis/reviews" exact component={Reviews} />
              <Route path="/genesis/career" exact component={Career} />
              <Route path="/genesis/services" exact component={Services} />
              <Route path="/genesis/team" exact component={Team} />
              <Route
                path="/les-produits-360/access-360"
                exact
                component={Access360}
              />
              <Route
                path="/les-produits-360/cookie-banner"
                exact
                component={CookieBanner}
              />
              <Route
                path="/les-produits-360/chat-messenger"
                exact
                component={ChatMessenger}
              />
              <Route
                path="/les-produits-360/insertion-why-buy"
                exact
                component={WhyBuyPage}
              />
              <Route
                path="/documentation-technique/formulaires"
                exact
                component={Forms}
              />
              <Route
                path="/documentation-technique/formulaires/financing"
                exact
                component={FormsFinancing}
              />
              <Route
                path="/documentation-technique/formulaires/general"
                exact
                component={FormsGeneral}
              />
              <Route
                path="/documentation-technique/formulaires/parts"
                exact
                component={FormsParts}
              />
              <Route
                path="/documentation-technique/formulaires/powerhouse"
                exact
                component={FormsPowerhouse}
              />
              <Route
                path="/documentation-technique/formulaires/sales-new"
                exact
                component={FormsSalesNew}
              />
              <Route
                path="/documentation-technique/formulaires/sales-preowned"
                exact
                component={FormsSalesPreowned}
              />
              <Route
                path="/documentation-technique/formulaires/service"
                exact
                component={FormsService}
              />
              <Route
                path="/documentation-technique/slugs-des-departements"
                exact
                component={DepartmentSlugs}
              />
              <Route
                path="/documentation-technique/bodystyles"
                exact
                component={Bodystyles}
              />
              <Route
                path="/documentation-technique/colanders"
                exact
                component={Colanders}
              />
              <Route
                path="/documentation-technique/framestyles"
                exact
                component={Framestyles}
              />
              <Route
                path="/documentation-technique/usedframes"
                exact
                component={Usedframes}
              />
              <Route
                path="/documentation-technique/marques"
                exact
                component={Makes}
              />
              <Route
                path="/documentation-technique/accessibilite"
                exact
                component={Accessibilite}
              />
              <Route
                path="/documentation-technique/datadog"
                exact
                component={Datadog}
              />
              <Route
                path="/documentation-technique/inventaire"
                exact
                component={InventoryTechnicalDoc}
              />
              <Route
                path="/documentation-technique/videos"
                exact
                component={Videos}
              />
              <Route
                path="/documentation-technique/sprint-reviews"
                exact
                component={SprintReviews}
              />
              <Route
                path="/documentation-technique/workflow"
                exact
                component={Workflow}
              />
              <Route
                path="/documentation-technique/google-map"
                exact
                component={GoogleMap}
              />
              <Route
                path="/documentation-technique/cles-de-config"
                exact
                component={ConfigKeys}
              />
              <Route
                path="/documentation-technique/programmes-manufacturiers"
                exact
                component={ManufacturerPrograms}
              />
              <Route
                path="/pam/ticket-creation"
                exact
                component={TicketCreation}
              />
              <Route path="/pam/ticket-status" exact component={TicketStatus} />
              <Route path="/pam/issue-types" exact component={IssueTypes} />
              <Route path="/pam/teams" exact component={Teams} />
              <Route path="/pam/resolutions" exact component={Resolutions} />
              <Route
                path="/pam/good-practices"
                exact
                component={GoodPractices}
              />
              <Route path="/pam/tooltips" exact component={Tooltips} />
              <Route path="/pam/faq" exact component={Faq} />
              <Route path="*">
                <NoResult />
              </Route>
            </Switch>
          </Fragment>
        ) : (
          <Login
            clienId={CLIENT_ID}
            authedUser={this.responseGoogleSuccess}
            connexionFailed={this.state.connexionFailed}
          />
        )}
      </Router>
    );
  }
}

function mapStateToProps() {
  return {};
}
export default connect(mapStateToProps, { handleInitialData })(App);
