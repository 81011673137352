import { _getNavigation } from "../../DATA/api";

import { getNavigation } from "./navigation";

export function getInitialData() {
  return Promise.all([_getNavigation()]).then(
    ([navigation, departmentSlugs]) => ({
      navigation,
    })
  );
}

export function handleInitialData() {
  return (dispatch) => {
    return getInitialData().then(({ navigation }) => {
      dispatch(getNavigation(navigation));
    });
  };
}
