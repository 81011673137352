import { Headline } from "../../_library/atoms";
import { Date } from "../../_library/atoms";

const MainContent = (props) => {
  const { h1, date } = props;
  return (
    <div className="w-full bg-layout">
      <header className="w-full px-2 py-2 pt-4 border-gray-200 bg-breadcrumb md:px-8">
        <Headline title={h1} />
        <Date date={date} />
      </header>
      <div className="h-full bg-white border-2 border-gray-200 shadow-2xl">
        {props.children}
      </div>
    </div>
  );
};

export default MainContent;
