import Template from "../Template";
import data from "../../DATA/content/documentation-technique/framestyles.json";
export default function App(props) {
  return (
    <Template
      filepath="documentation-technique/framestyles.json"
      location={props.location.pathname}
      h1="Framestyles"
      arrayStart="3"
      arrayEnd="4"
      data={data}
      layout="table"
    />
  );
}
