import { connect } from "react-redux";
import { Layout, SidebarElement } from "../../atoms";
import { MainContent, Sidebar } from "../../molecule";

const Timeline = (props) => {
  const { location, h1, arrayStart, arrayEnd, navigationIds, data } = props;

  return (
    <Layout>
      <Sidebar>
        {navigationIds.slice(arrayStart, arrayEnd).map((id) => (
          <SidebarElement
            id={id}
            key={id}
            isActive="true"
            location={location}
          />
        ))}
      </Sidebar>
      <MainContent h1={h1}>
        <section>
          <div className=" py-8">
            <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
              <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
                <div className="container mx-auto w-full h-full">
                  <div className="relative wrap overflow-hidden p-10 h-full">
                    <div className="border-2-2 border-yellow-555 absolute h-full border right-2/4 border-primary rounded"></div>

                    {data.map((value, index) => (
                      <>
                        <div
                          key={index}
                          className={`mb-8 flex justify-between items-center w-full left-timeline ${
                            index % 2 === 0 ? "flex-row-reverse" : ""
                          }`}
                        >
                          <div className="order-1 w-5/12"></div>
                          <div
                            className={`order-1 w-5/12 px-1 py-4 ${
                              index % 2 === 0 ? "text-right" : "text-left"
                            }`}
                          >
                            <p className="mb-3 text-base text-primary">
                              {value.date}
                            </p>
                            <h4 className="mb-3 font-bold text-lg md:text-2xl">
                              {value.title}
                            </h4>
                            <p className="text-sm md:text-base leading-snug  text-opacity-100 mb-4">
                              {value.tagline}
                            </p>
                            {value.url && (
                              <a href={value.url} className="button">
                                Voir le détail
                              </a>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainContent>
    </Layout>
  );
};

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(Timeline);
