import { GoogleLogout } from "react-google-login";

const Logout = (props) => {
  const { clientId, onLogoutSuccess } = props;

  return (
    <GoogleLogout
      clientId={clientId}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className="hover:underline"
        >
          Logout
        </button>
      )}
      buttonText={"Logout"}
      onLogoutSuccess={onLogoutSuccess}
    ></GoogleLogout>
  );
};

export default Logout;
