import { connect } from "react-redux";
import { Link } from "react-router-dom";

const SidebarElement = (props) => {
  const { nav, location } = props;
  const { subNavigation } = nav;
  const activeClass = "text-primary";
  const activeClassSecondNav = "text-primary";

  return (
    <>
      <div className="p-2 border-t-2 border-l-4  text-primary border-primary">
        {nav.nameFr}
      </div>
      {subNavigation.map((item,index) => (
        <li
          key={index}
          className={`py-1 pl-4 border-l-4  ${
            location === item.href ? activeClass : "border-primary text-white"
          }`}
        >
          <Link
            to={item.href}
            className={` ${
              location === item.href
                ? activeClassSecondNav
                : "text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary "
            }`}
          >
            {item.nameFr}
          </Link>

          {item.secondNav && (
            <ul className="text-white ">
              {item.secondNav.map((secondaryNav) => (
                <li
                  key={secondaryNav.id}
                  className={` ${
                    location === secondaryNav.href ? "" : "border-secondary"
                  }`}
                >
                  <Link
                    to={secondaryNav.href}
                    className={`pl-4 ${
                      location === secondaryNav.href
                        ? activeClassSecondNav
                        : "text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-sm"
                    }`}
                  >
                    {secondaryNav.nameFr}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </>
  );
};

function mapStateToProps({ navigation }, { id }) {
  const nav = navigation[id];
  return {
    nav,
    navigation,
  };
}
export default connect(mapStateToProps)(SidebarElement);
