import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="documentation-technique/department-slugs.md"
      location={props.location.pathname}
      h1="Slugs des départements"
      arrayStart="3"
      arrayEnd="4"
    />
  );
}
