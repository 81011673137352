import Template from "../Template";
import data from "../../DATA/content/infos-des-websites/infos-des-websites-mobile.json";
export default function App(props) {
  return (
    <Template
      filepath="infos-des-websites/infos-des-website-mobile.json"
      location={props.location.pathname}
      h1="Plateformes mobile"
      dateUpdated="26 janvier 2023"
      arrayStart="0"
      arrayEnd="1"
      data={data}
      layout="table"
    />
  );
}
