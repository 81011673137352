import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="genesis/homepage.md"
      location={props.location.pathname}
      h1="Homepage"
      arrayStart="1"
      arrayEnd="2"
    />
  );
}
