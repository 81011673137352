import { connect } from "react-redux";
import { Layout, SidebarElement } from "../../atoms";
import { MainContent, Sidebar } from "../../molecule";

const Genesis = (props) => {
  const { location, h1, arrayStart, arrayEnd, navigationIds, data } = props;

  return (
    <Layout>
      <Sidebar>
        {navigationIds.slice(arrayStart, arrayEnd).map((id) => (
          <SidebarElement
            id={id}
            key={id}
            isActive="true"
            location={location}
          />
        ))}
      </Sidebar>
      <MainContent h1={h1}>
        <div className="overflow-x-auto">
          {data.map((ObjectMapped) => (
            <div key={ObjectMapped.title}>
              <div className="bg-primary text-white p-2">
                <p>{ObjectMapped.title}</p>
                <div>{ObjectMapped.description}</div>
              </div>
              <table className="table-fixed">
                <thead>
                  <tr>
                    <td>Mobile</td>
                    <td>Tablette</td>
                    <td>Desktop</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src={ObjectMapped.mobile} alt={ObjectMapped.title} />
                    </td>
                    <td>
                      <img src={ObjectMapped.tablet} alt={ObjectMapped.title} />
                    </td>
                    <td>
                      <img
                        src={ObjectMapped.desktop}
                        alt={ObjectMapped.title}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </MainContent>
    </Layout>
  );
};

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(Genesis);
