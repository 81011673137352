import { connect } from "react-redux";
import MarkdownFiles from "../utils/MarkdownFiles";

import Table from "../_library/organisms/templates/Table";
import Genesis from "../_library/organisms/templates/Genesis";
import Documentation from "../_library/organisms/templates/Documentation";
import Timeline from "../_library/organisms/templates/Timeline";

function Template(props) {
  const {
    filepath,
    location,
    h1,
    dateUpdated,
    arrayStart,
    arrayEnd,
    navigationIds,
    organism,
    data,
    layout,
  } = props;

  switch (layout) {
    case "table":
      return (
        <Table
          filepath={filepath}
          location={location}
          h1={h1}
          dateUpdated={dateUpdated}
          arrayStart={arrayStart}
          arrayEnd={arrayEnd}
          navigationIds={navigationIds}
          organism={organism}
          data={data}
        />
      );
    case "genesis":
      return (
        <Genesis
          filepath={filepath}
          location={location}
          h1={h1}
          dateUpdated={dateUpdated}
          arrayStart={arrayStart}
          arrayEnd={arrayEnd}
          navigationIds={navigationIds}
          organism={organism}
          data={data}
        />
      );
    case "timeline":
      return (
        <Timeline
          filepath={filepath}
          location={location}
          h1={h1}
          arrayStart={arrayStart}
          arrayEnd={arrayEnd}
          navigationIds={navigationIds}
          organism={organism}
          data={data}
        />
      );

    case "documentation":
      return (
        <Documentation
          filepath={filepath}
          location={location}
          h1={h1}
          dateUpdated={dateUpdated}
          arrayStart={arrayStart}
          arrayEnd={arrayEnd}
          navigationIds={navigationIds}
          organism={organism}
          data={data}
        />
      );

    default:
      return (
        <MarkdownFiles
          filepath={filepath}
          location={location}
          h1={h1}
          dateUpdated={dateUpdated}
          arrayStart={arrayStart}
          arrayEnd={arrayEnd}
          navigationIds={navigationIds}
          organism={organism}
          data={data}
        />
      );
  }
}

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(Template);
