import { connect } from "react-redux";
import { Popover } from "@headlessui/react";

import NavigationItem from "../molecule/NavigationItem";

const Navigation = (props) => {
  const { navigationIds } = props;

  return (
    <Popover.Group as="nav" className="hidden md:flex space-x-10 ">
      {navigationIds.map((id, index) => (
        <div key={index}>
          <NavigationItem id={id} />
        </div>
      ))}
    </Popover.Group>
  );
};

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(Navigation);
