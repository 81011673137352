const SubNavigationItem = (props) => {
  const { item } = props;
  const { nameFr, href } = item;
  const activeClass = "border-primary";

  return (
    <a
      key={nameFr}
      href={href}
      className={`p-2 border-l-4  text-white ${
        window.location.pathname === item.href
          ? activeClass
          : "border-secondary"
      }`}
    >
      {nameFr}
    </a>
  );
};

export default SubNavigationItem;
