import { connect } from "react-redux";
import { Layout, SidebarElement } from "../../atoms";
import { MainContent, Sidebar } from "../../molecule";

const Columns = (props) => {
  const { ObjectMapped, index } = props;
  
  let columnName = [];

  Object.entries(ObjectMapped).forEach(([key],index) => {
    columnName.push(<th key={index}>{key}</th>);
  });

  return <tr key={index}>{columnName}</tr>;
};

const AllData = (props) => {
  const { ObjectMapped, index } = props;

  let datas = [];
  let formatValue;
  Object.entries(ObjectMapped).forEach(([key, value], index) => {
    switch (value) {
      case 0:
        formatValue = <span className="" />;
        break;
      case 1:
        formatValue = <span className="bullet-active" title={key} />;
        break;
      default:
        formatValue = value;
    }

    datas.push(<td key={index}>{formatValue}</td>);
  });
  return <tr key={index}>{datas}</tr>;
};

const Table = (props) => {
  const {
    location,
    h1,
    dateUpdated,
    arrayStart,
    arrayEnd,
    navigationIds,
    data,
  } = props;

  return (
    <Layout>
      <Sidebar>
        {navigationIds.slice(arrayStart, arrayEnd).map((id,index) => (
          <SidebarElement
            id={id}
            key={index}
            isActive="true"
            location={location}
          />
        ))}

      </Sidebar>
      <MainContent h1={h1} date={dateUpdated}>
        <div className="overflow-x-auto">
          <table>
            <thead>
              {data.map((ObjectMapped, index) => (
                <Columns index={index} ObjectMapped={ObjectMapped} />
              ))}
            </thead>
            <tbody>
              {data.map((ObjectMapped, index) => (
                <AllData index={index} ObjectMapped={ObjectMapped} />
              ))}
            </tbody>
          </table>
        </div>
      </MainContent>
    </Layout>
  );
};

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(Table);
