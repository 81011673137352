import { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import { Layout, SidebarElement } from "../_library/atoms";
import { MainContent, Sidebar } from "../_library/molecule";

const MarkdownFiles = (props) => {
  const [data, setData] = useState("");
  const { filepath, location, h1, arrayStart, arrayEnd, navigationIds } = props;
  useEffect(() => {
    import(`../DATA/content/${filepath}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setData(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });

  return (
    <Layout>
      <Sidebar>
        {navigationIds.slice(arrayStart, arrayEnd).map((id) => (
          <SidebarElement
            id={id}
            key={id}
            isActive="true"
            location={location}
          />
        ))}
      </Sidebar>
      <MainContent h1={h1}>
        <div className="py-8 px-2 md:px-8">
          <Markdown>{data}</Markdown>
        </div>
      </MainContent>
    </Layout>
  );
};

export default MarkdownFiles;
