import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="documentation-technique/videos.md"
      location={props.location.pathname}
      h1="Vidéos"
      arrayStart="3"
      arrayEnd="4"
    />
  );
}
