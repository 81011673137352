import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="produits-360/access-360.md"
      location={props.location.pathname}
      h1="Access 360"
      arrayStart="2"
      arrayEnd="3"
    />
  );
}
