import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="infos-des-websites/infos-des-websites-partageables.md"
      location={props.location.pathname}
      h1="Plateformes partageables"
      arrayStart="0"
      arrayEnd="1"
    />
  );
}
