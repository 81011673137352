import { connect } from "react-redux";

import NavigationItemMobile from "../molecule/NavigationItemMobile";

const Navigation = (props) => {
  const { navigationIds } = props;

  return (
    <nav className="grid gap-y-8 pb-4 bg-secondary">
      {navigationIds.map((id) => (
        <div key={id} className="">
          <NavigationItemMobile id={id} />
        </div>
      ))}
    </nav>
  );
};

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(Navigation);
