import Template from "./Template";

export default function App(props) {
  return (
    <Template
      filepath="app.md"
      location={props.location.pathname}
      h1="Quoi de neuf?"
    />
  );
}
