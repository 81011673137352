import { NavLink } from "react-router-dom";

const SubNavigationItem = (props) => {
  const { item } = props;
  const { nameFr, description, href } = item;

  const activeClass = "border-primary";

  return (
    <NavLink to={href} className="hover:no-underline">
      <div
        className={`px-5 p-2  border-l-4  ${
          window.location.pathname === item.href
            ? activeClass
            : "border-white text-secondary"
        }`}
      >
        <span className="text-base font-medium  block hover:underline">
          {nameFr}
        </span>
        <span className="text-sm text-gray-500">{description}</span>
      </div>
    </NavLink>
  );
};

export default SubNavigationItem;
