import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="genesis/footer.md"
      location={props.location.pathname}
      h1="Footer"
      arrayStart="1"
      arrayEnd="2"
    />
  );
}
