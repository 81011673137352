import { connect } from "react-redux";
import { Layout, SidebarElement } from "../../atoms";
import { MainContent, Sidebar } from "../../molecule";

const JsonFiles = (props) => {
  const { location, h1, arrayStart, arrayEnd, navigationIds, data } = props;

  return (
    <Layout>
      <Sidebar>
        {navigationIds.slice(arrayStart, arrayEnd).map((id) => (
          <SidebarElement
            id={id}
            key={id}
            isActive="true"
            location={location}
          />
        ))}
      </Sidebar>
      <MainContent h1={h1}>
        <section className="relative h-screen pt-12">
          {data.map((value, index) => (
            <div
              className="flex flex-wrap mb-5 border-b-2"
              key={`inventory-${index}`}
            >
              <div className="w-full px-4 ml-auto mr-auto ">
                <div className="md:pr-12">
                  <p className="p-2 text-white bg-primary">{value.title}</p>
                  <p className="">{value.tagline}</p>
                  <p className="">{value.description}</p>
                  <div className="w-full px-4 ml-auto mr-auto">
                    {value.imageUrl && (
                      <img
                        alt={value.title}
                        className="max-w-full rounded-lg shadow-lg"
                        src={value.imageUrl}
                      />
                    )}
                    {value.videoUrl && (
                      <iframe
                        src={value.videoUrl}
                        title={value.title}
                        width=""
                        height=""
                      />
                    )}
                  </div>
                </div>
              </div>

              {value.properties[0].propertyKey !== "" && (
                <div className="w-full px-4 ml-auto mr-auto">
                  <table className="container mx-auto">
                    <thead>
                      <tr>
                        <th>Clé</th>
                        <th>Valeurs possibles</th>
                        <th>Valeur par défaut</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value.properties.map((property, i) => (
                        <tr key={i}>
                          <td>{property.propertyKey}</td>
                          <td>{property.propertyPossibleValue}</td>
                          <td>{property.propertyDefaultValue}</td>
                          <td>{property.propertyDescription}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </section>
      </MainContent>
    </Layout>
  );
};

function mapStateToProps({ navigation }) {
  return {
    navigationIds: Object.keys(navigation),
  };
}
export default connect(mapStateToProps)(JsonFiles);
