import Template from "../Template";
import data from "../../DATA/content/documentation-technique/makes.json";
export default function App(props) {
  return (
    <Template
      filepath="documentation-technique/makes.json"
      location={props.location.pathname}
      h1="Marques"
      arrayStart="3"
      arrayEnd="4"
      data={data}
      layout="table"
    />
  );
}
