import { connect } from "react-redux";
import SubNavigationItemMobile from "./SubNavigationItemMobile";

const NavigationItemMobile = (props) => {
  const { nav } = props;
  const { nameFr, subNavigation } = nav;

  return (
    <nav className="grid gap-y-8">
      <span className="bg-primary p-2  px-5">{nameFr}</span>
      {subNavigation.map((item) => (
        <div key={item.id}>
          <SubNavigationItemMobile item={item} />
        </div>
      ))}
    </nav>
  );
};

function mapStateToProps({ navigation }, { id }) {
  const nav = navigation[id];
  return {
    nav,
    navigation,
  };
}
export default connect(mapStateToProps)(NavigationItemMobile);
