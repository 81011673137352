import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import Logo from "../molecule/Logo";

import Navigation from "./Navigation";
import NavigationMobile from "./NavigationMobile";
import AuthedUser from "./AuthedUser";

export default function Header(props) {
  const { clienId, onLogoutSuccess, userInfo } = props;
  return (
    <Popover className="relative">
      <div className="bg-gradient-to-r from-primary to-primaryDarken">
        <div className="flex justify-between items-center md:justify-start md:space-x-10 shadow-md md:bg-gradient-to-r from-primary to-primaryDarken md:text-white">
          <div className=" p-5 w-9/12 md:w-52 flex justify-center ">
            <Logo />
          </div>
          <div className="md:hidden absolute right-0 ">
            <Popover.Button className="rounded-md p-2 mr-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="flex justify-between w-10/12">
            <Navigation />
            <AuthedUser
              clienId={clienId}
              onLogoutSuccess={onLogoutSuccess}
              userInfo={userInfo}
            />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-15 inset-x-0 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 ">
            <div className="flex items-center justify-between">
              <div className="">
                <Popover.Button
                  className="
                  rounded-md
                  p-2
                  inline-flex
                  items-center
                  justify-center
                  text-gray-400
                  hover:text-gray-500
                  hover:bg-gray-100
                  focus:outline-none
                  focus:ring-2
                  focus:ring-inset
                  focus:ring-secondary
                  absolute
                  -top-12
                  right-2
                  bg-secondary
                  h-10
                  w-10"
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            <NavigationMobile />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
