import Template from "../../Template";
import data from "../../../DATA/content/documentation-technique/formulaires/forms-general.json";
export default function App(props) {
  return (
    <Template
      filepath="documentation-technique/formulaires/forms-general.json"
      location={props.location.pathname}
      h1="Forms General"
      arrayStart="3"
      arrayEnd="4"
      data={data}
      layout="table"
    />
  );
}
