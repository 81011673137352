import Template from "../Template";

export default function App(props) {
  return (
    <Template
      filepath="pam/teams.md"
      location={props.location.pathname}
      h1="Teams"
      arrayStart="4"
      arrayEnd="5"
    />
  );
}
